<template>
  <v-input :prepend-icon="icon" class="mt-0 input-row" :label="label" :disabled="disabled" hide-details>
    <template>

  <v-row>
    <v-col cols="4" :class="dense ? 'pa-0' : 'py-0'">
      <v-text-field
        v-model.number="hours"
        type="number" 
        step="any"
        min="0"
        max="12"
        required
        persistent-hint
        aria-label="Hours"
        class="mt-0 pt-0"
        :disabled="disabled"
        :hint="$t('shared.hours')"
        :rules="mergedRules"
        @change="handleChange"
      ></v-text-field>
    </v-col>
    <v-col cols="4" :class="dense ? 'pa-0' : 'py-0'">
      <v-text-field
        v-model.number="minutes"
        type="number" 
        step="any"
        min="0"
        max="59"
        required
        persistent-hint
        aria-label="Minutes"
        class="mt-0 pt-0"
        :disabled="disabled"
        :hint="$t('shared.minutes')"
        :rules="mergedRules"
        @change="handleChange"
      ></v-text-field>
    </v-col>
    <v-col cols="4" :class="dense ? 'pa-0' : 'py-0'">
      <v-text-field
        v-model.number="seconds"
        type="number" 
        step="any"
        min="0"
        max="59"
        required
        persistent-hint
        aria-label="Seconds"
        class="mt-0 pt-0"
        :disabled="disabled"
        :hint="$t('shared.seconds')"
        :rules="mergedRules"
        @change="handleChange"
      ></v-text-field>
    </v-col>
  </v-row>
    </template>
  </v-input>

</template>

<script>
import { Duration } from 'luxon';

export default {
  name: "DurationTextArea",
  components: {
  },
  props: {
    value: Number,
    label: String,
    icon: String,
    rules: Array,
    dense: Boolean,
    disabled: Boolean,
    required: Boolean,
    form: Object,
  },
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      val: 0,
      defaultRules: [
        () => (this.required ? this.val > 0 : true) || 'Please enter the duration.',
      ],
    };
  },
  async mounted() {
  },
  methods: {
    handleChange(e) {
      if (!Number.isFinite(this.hours)) {
        this.hours = 0;
      }
      if (!Number.isFinite(this.minutes)) {
        this.minutes = 0;
      }
      if (!Number.isFinite(this.seconds)) {
        this.seconds = 0;
      }

      this.val = (3600*parseInt(this.hours))+ (60*parseInt(this.minutes)) + parseInt(this.seconds);
      console.log(`[duration] changed to ${this.val}.`);
      if (this.form) {
        this.form.validate();
      }
      this.$emit('input', this.val); 
    },
  },
  computed: {
    mergedRules() {
      return this.rules ? this.rules.concat(this.defaultRules) : this.defaultRules;
    },
  },
  watch: {
    value: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        var duration = Duration.fromMillis(val * 1000).shiftTo('hours', 'minutes', 'seconds').toObject();
        this.hours = duration.hours;
        this.minutes = duration.minutes;
        this.seconds = duration.seconds;
      }
    },
  }

};
</script>
<style lang="scss">
</style>

