<template>
  <div v-if="!eventUtil.newLayout">
  <v-toolbar v-if="!event && title" :dark="$helpers.isDarkColor($vuetify.theme.themes.light.eventmanager)" elevation="1" color="eventmanager">
    <v-toolbar-title class="ml-4">{{title}}</v-toolbar-title>
  </v-toolbar>
  <v-toolbar v-if="event" :dark="$helpers.isDarkColor($vuetify.theme.themes.light.eventmanager)" elevation="1" color="eventmanager">
    <v-toolbar-title class="ml-4"><router-link class="no-decoration" :to="{name:'eventmanagerView', params: {id: event.id}}">{{event.name}}</router-link></v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <v-btn text :href="tenant.eventManagerFaqUrl" target="_blank" class="mr-4">
        <v-icon class="mr-2">fadl fa fa-question-circle</v-icon>
        FAQ
      </v-btn>
      <v-btn v-if="!focused && event.published" :to="{name:'event', params: {id: event.id}}" class="mr-4">
        <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
        View
      </v-btn>
      <v-btn v-if="!focused" :to="{name:'eventmanagerEdit', params: {id: event.id}}" class="mr-0">
        <v-icon small class="mr-2">fadl fa fa-pencil</v-icon>
        Edit
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon title="More options" >fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :disabled="!eventUtil.isProPlan()" :to="{name:'eventmanagerGallery', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-images</v-icon></v-list-item-icon>
            <v-list-item-title>Photos &amp; selfies</v-list-item-title>
            <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
          </v-list-item>
          <v-list-item :disabled="!eventUtil.isProPlan()" :to="{name:'eventmanagerReports', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-file-spreadsheet</v-icon></v-list-item-icon>
            <v-list-item-title>Export reports</v-list-item-title>
            <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
          </v-list-item>
          <v-list-item :disabled="!eventUtil.isProPlan()" :to="{name: 'eventmanagerCertificates', params: { id:event.id }}">
            <v-list-item-icon><v-icon>fa-file-certificate</v-icon></v-list-item-icon>
            <v-list-item-title>Virtual Bibs &amp; Certificates</v-list-item-title>
            <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
          </v-list-item>
          <v-list-item :disabled="!eventUtil.isProPlan()" :to="{name:'eventmanagerActivityConversions', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-pencil-ruler</v-icon></v-list-item-icon>
            <v-list-item-title>Activity Conversion Matrix</v-list-item-title>
            <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
          </v-list-item>
          <v-divider/>
          <v-list-item :disabled="!eventUtil.isProPlan()" :to="{name:'eventmanagerIntegrations', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-key</v-icon></v-list-item-icon>
            <v-list-item-title>Integrations</v-list-item-title>
            <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
          </v-list-item>
          <v-list-item :disabled="!eventUtil.isProPlan()" :to="{name:'eventmanagerPermissions', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-user-unlock</v-icon></v-list-item-icon>
            <v-list-item-title>Permissions</v-list-item-title>
            <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <template v-slot:extension v-if="!focused">
        <v-tabs
          class="mx-0"
          background-color="eventmanager darken-1"
          slider-color="white"
        >
          <v-tab
            v-for="(tab, idx) in tabs"
            :key="idx"
            :to="tab.to"
            :disabled="tab.disabled"
          >
            {{ tab.text }} 
            <v-chip v-if="tab.disabled" small class="ml-2">PRO</v-chip>
          </v-tab>
        </v-tabs>
      </template>    
  </v-toolbar>
  </div>
</template>

<script>
import eventManagerService from "@/services/eventManagerService";
import tenants from '@/data/tenants.config'
import EventUtil from "@/util/eventUtil";
const tenant = tenants.current();

export default {
  name: "EventManagerHeader",
  components: {
  },
  props: {
    event: Object,
    title: String,
    focused: Boolean,
  },
  data() {
    return {
      tenant: tenant,
    };
  },
  async mounted() {
  },
  methods: {

  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    tabs() {
      return this.event == null ? [] : [
        { text: 'Leaderboards', to: {name:'eventmanagerView', params: {id: this.event.id}} },
        (tenant.id == 'cofi') ? { text: 'Feed', to: {name:'eventmanagerFeed', params: {id: this.event.id}} } : null,
        { text: 'Participants', to: {name:'eventmanagerParticipants', params: {id: this.event.id}} },
        { text: 'Teams', disabled: !this.eventUtil.isProPlan(), to: {name:'eventmanagerTeams', params: {id: this.event.id}} },
        { text: 'Registrations', disabled: !this.eventUtil.isProPlan(), to: {name:'eventmanagerRegistrations', params: {id: this.event.id}} } ,
      ].filter(x => x != null);
    }
  },
};
</script>
<style lang="scss" scoped>
  .v-toolbar {
    .v-toolbar__title a {
      color: black! important;
    }
  }
  .v-toolbar.theme--dark {
    .v-toolbar__title a {
      color: white! important;
    }
  }
</style>

