<template>
  <div class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <Header title="Ads"/>

      <v-card-text>      
        Create and manage ads.
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="ads"
        :items-per-page="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Ads</v-toolbar-title>
            <v-spacer/>
            <v-dialog v-model="showDialog" max-width="650px" @input="v => v || closeDialog()">
              <template v-slot:activator="{ on }">
                <v-btn color="accent" dark v-on="on"><v-icon small class="mr-2">fa-plus</v-icon> New Ad</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">{{ formTitle }}</v-card-title>
                <v-form v-model="valid" lazy-validation ref="adForm">
                <v-expansion-panels accordion tile class="mb-4" v-model="initialPanel">  
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
                      Ad {{editedItem.name}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field 
                        v-model="editedItem.name" 
                        prepend-icon="fa fa-pencil"
                        class="mb-8"
                        label="Ad name" 
                        persistent-hint 
                        hint="This name is not visible to users, it's only for you to keep track of this ad" 
                        required 
                        :rules="nameRules"
                        />

                      <v-text-field 
                        v-model="editedItem.url" 
                        prepend-icon="fa fa-link"
                        class="mb-8"
                        label="Target url" 
                        persistent-hint 
                        hint="Enter the full url (starting with 'https://' and including any tracking codes you might want to use)" 
                        required 
                        :rules="urlRules"
                        />

                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel >
                    <v-expansion-panel-header>
                      <v-icon color="gray" class="shrink mr-4">fadl fal fa-tools</v-icon>
                      Configuration
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-switch v-model="editedItem.active" class="mt-0" label="Enabled" hint="Toggle off to hide this add for everyone" persistent-hint></v-switch>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <DateWithTimeZonePicker v-model="editedItem.from" :timeZone="editedItem.timezone" clearable label="Active from (included)" class="pt-0 mt-0" />
                        </v-col>
                        <v-col cols="6">
                          <DateWithTimeZonePicker v-model="editedItem.till" :timeZone="editedItem.timezone" clearable label="Active till (included)" class="pt-0 mt-0" />
                        </v-col>
                      </v-row>
                      <v-text-field
                        label="Time zone (read only)"
                        readonly
                        prepend-icon="fa fa-globe"
                        v-model="editedItem.timezone"
                        />
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-icon color="gray" class="shrink mr-4">fadl fal fa-filter</v-icon>
                      Filters ({{ getFilterCount(editedItem) }})
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p>This ad will only be displayed to users matching <strong>all</strong> configured filters.</p>
                      <h4>Profile Filters</h4>
                      <!-- Disabled until we collect this information -->
                      <v-row v-if="false" no-gutters>
                        <v-col cols="6">
                          <DistanceTextArea
                            v-model="editedItem.filter_age_min" 
                            :mode="$helpers.UnitType.NUMBER"
                            :label="`Min. age`" 
                            />
                        </v-col>
                        <v-col cols="6">
                          <DistanceTextArea
                            v-model="editedItem.filter_age_max" 
                            :mode="$helpers.UnitType.NUMBER"
                            :label="`Max. age`" 
                            />
                        </v-col>
                      </v-row>
                      <v-radio-group v-model="editedItem.filter_gender" row class="mt-0" label="Gender" >
                        <v-radio label="All" :value="null"></v-radio>
                        <v-radio :label="$t('shared.gender-male')" value="M"></v-radio>
                        <v-radio :label="$t('shared.gender-female')" value="F"></v-radio>
                      </v-radio-group>
                      <h4>Activity Filters</h4>
                      <v-select
                        :items="siteData.activities"
                        v-model="editedItem.filter_activity_types" 
                        multiple
                        item-value="type"
                        item-text="text"
                        placeholder="All Sports"
                        label="Allowed Sports (at least one should match)"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item 
                            @click="checkAllActivities"
                            :class="allActivitiesSelected ? 'v-list-item--active':''"
                          >
                            <v-list-item-action>
                              <v-icon color="primary">{{ allActivitiesSelected ? 'fa-check-square' : 'fal fa-square' }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>All sports</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <DistanceTextArea
                            v-model="editedItem.filter_dist_min" 
                            :unit="profile ? profile.unit : 'METRIC'"
                            :mode="$helpers.UnitType.DISTANCE"
                            :label="`Min. distance (in ${unitTypeDisplay})`" 
                            />
                        </v-col>
                        <v-col cols="6">
                          <DistanceTextArea
                            v-model="editedItem.filter_dist_max" 
                            :unit="profile ? profile.unit : 'METRIC'"
                            :mode="$helpers.UnitType.DISTANCE"
                            :label="`Max. distance (in ${unitTypeDisplay})`" 
                            />
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <DistanceTextArea
                            v-model="editedItem.filter_duration_min" 
                            :mode="$helpers.UnitType.NUMBER"
                            :multiplier="3600"
                            :label="`Min. duration (in hours)`" 
                            hint="Tip: Enter 0.5 for half an hour."
                            />
                        </v-col>
                        <v-col cols="6">
                          <DistanceTextArea
                            v-model="editedItem.filter_duration_max" 
                            :mode="$helpers.UnitType.NUMBER"
                            :multiplier="3600"
                            :label="`Max. duration (in hours)`" 
                            />
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <DistanceTextArea
                            v-model="editedItem.filter_coord_lat" 
                            :mode="$helpers.UnitType.NUMBER"
                            :label="`Coordinate (latitude)`" 
                            />
                        </v-col>
                        <v-col cols="3">
                          <DistanceTextArea
                            v-model="editedItem.filter_coord_lon" 
                            :mode="$helpers.UnitType.NUMBER"
                            :label="`Coordinate (longitude)`" 
                            />
                        </v-col>
                        <v-col cols="3">
                          <DistanceTextArea
                            v-model="editedItem.filter_coord_radius" 
                            :unit="profile ? profile.unit : 'METRIC'"
                            :mode="$helpers.UnitType.DISTANCE"
                            :label="`Radius around coordinate (in ${unitTypeDisplay})`" 
                            />
                        </v-col>
                        <v-col cols="3">
                          <v-btn @click="showMapDialog=true">Select on map</v-btn>
                        </v-col>
                      </v-row>
                      <!-- <p class="text-muted">Use <a href="https://www.latlong.net/" target="_blank">this webpage</a> to quickly find the coordinate of any location.</p> -->
                      <h4>Event Filters</h4>
                      <v-combobox
                        v-model="editedItem.filter_event_ids"
                        label="Show to participant of these event (enter their event id's)"
                        hint="Ad will be shown to participants of any of above events."
                        persistent-hint
                        multiple
                        chips
                        />
                      <v-combobox
                        v-model="editedItem.filter_series_group_ids"
                        label="Show to participant of these series (enter their group id's)"
                        hint="Ad will be shown to participants of any of above series."
                        persistent-hint
                        multiple
                        chips
                        />
                      <v-text-field
                        v-model="editedItem.filter_org_group_id"
                        label="Show to participants of any event of this organizer (enter their group id)"
                        />

                      <h4>Device Filters</h4>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.filter_device_brand"
                            label="Device brand"
                            persistent-hint
                            hint="Examples: 'garmin'"
                            />
                        </v-col>
                        <v-col cols="6">
                          <!-- Disabled until we collect this information -->
                          <v-text-field
                            v-if="false"
                            v-model="editedItem.filter_device_model"
                            label="Device model"
                            persistent-hint
                            hint="Examples: 'forerunner 55'"
                            />
                        </v-col>
                      </v-row>
                      <v-text-field
                        v-model="editedItem.filter_app_name"
                        label="App name"
                        persistent-hint
                        hint="Examples: 'garmin' or 'mapmyrun'"
                        />

                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>                
                </v-form>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="closeDialog">Cancel</v-btn>
                  <v-btn color="secondary"  :loading="$store.getters.isLoading" @click="saveDialog">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="showDesignDialog" max-width="650px"  @input="v => v || closeDesignDialog()">
              <template v-slot:activator="{ on }">
              </template>
              <v-card>
                <v-card-title class="headline">Ad designer</v-card-title>
                <v-card-text>
                  <v-form v-model="valid" lazy-validation ref="designForm">
                    <v-file-input 
                      v-model="uploadFile" 
                      filled 
                      prepend-icon="fa fa-image" 
                      accept="image/*" 
                      :rules="uploadRules" 
                      label="Upload an image" 
                      v-on:change="uploadImage"
                      />
                  </v-form>

                  <div v-if="editedItem.img">
                    <h3>Preview</h3>
                    <v-sheet elevation="2">
                      <a :href="editedItem.url" target="_blank">
                        <v-img :src="editedItem.img" max-width="100%" max-height="150" contain />
                      </a>
                    </v-sheet>
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-btn color="secondary" text @click="closeDesignDialog">Cancel</v-btn> -->
                  <v-btn color="secondary" :loading="$store.getters.isLoading" @click="closeDesignDialog">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <div>
            {{ item.name }} 
          </div>
          <div class="text-muted">
            {{ item.url }} 
          </div>
        </template>
        <template v-slot:item.active="{ item }">
          <div>
            {{ item.active ? 'Enabled' : 'INACTIVE' }}
            <div v-if="item.active" class="text-muted">Between: {{ item.from | localDate }} and {{ item.till | localDate }}</div>
          </div>
        </template>
        <template v-slot:item.img="{ item }">
          <img :src="item.img" style="width:32px;" @click="openDesignDialog(item)"/>
        </template>
        <template v-slot:item.filters="{ item }">
          {{ getFilterCount(item) }} filter(s)
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small title="Edit" class="mr-4" @click="openDialog(item)">
            fa-pencil-alt
          </v-icon>
          <v-icon small title="Design" class="mr-4" @click="openDesignDialog(item)">
            fa-fill-drip
          </v-icon>
          <v-icon small title="Delete" class="mr-4" @click="deleteAd(item)">
            fa-trash
          </v-icon>
        </template>
        <template v-slot:no-data>
          <i>No ads created yet, why not create one now?</i>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showMapDialog">
      <v-card>  
        <v-card-title class="display-1">
          Select location
        </v-card-title>
        <v-card-text class="mt-4 pb-0">
          Click on the map to select a location and enter the radius
        </v-card-text>
        <v-row class="mx-4 my-0">
          <v-col class="grow">
          <DistanceTextArea
            v-model="editedItem.filter_coord_radius" 
            :mode="$helpers.UnitType.DISTANCE"
            :unit="profile ? profile.unit : 'METRIC'"
            @input="updateRadius"
            :label="`Radius (in ${unitTypeDisplay})`"
            />
          </v-col>
          <v-col class="shrink">
            <v-btn color="primary" :disabled="editedItem.filter_coord_lat === null || editedItem.filter_coord_lon === null || editedItem.filter_coord_radius === 0" @click="$forceUpdate();showMapDialog=false;">Select</v-btn>
          </v-col>
        </v-row>
        <LeafletMap 
          ref="leaflet"
          style="height: 500px; width: 100%; z-index: 2;" 
          :center="editedItem && editedItem.filter_coord_lat && editedItem.filter_coord_lon ? [editedItem.filter_coord_lat, editedItem.filter_coord_lon] : [0, 0]"
          :zoom="2"
          :loader="mapLoaded"
          />
        <v-card-actions>
          <v-btn text @click="showMapDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { DateTime } from 'luxon'
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import Header from './_Header.vue'
import DurationTextArea from "@/components/DurationTextArea";
import LeafletMap from "@/components/LeafletMap";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Ads",
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    LeafletMap,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      ads: [],
      showDialog: false,
      initialPanel: 2,
      showDesignDialog: false,
      uploadFile: null,
      valid: false,
      editedIndex: -1,
      editedItem: {active:true, },
      defaultItem: {active:true, },
      siteData: siteData,
      timeZoneOlson: null,
      profile: null,
      showMapDialog: false,
      marker: null,
      nameRules: [
        v => !!v || "Please enter the name",
      ],
      urlRules: [
        v => (!!v && v.length > "https://".length && v.startsWith("https://")) || "Please enter a valid url (starting with https://)",
      ],
      selectRules: [
        v => !!v || "Please select an item",
      ],
      uploadRules: [
        value => !value || value.size < 5000000 || 'Image size should be less than 5 MB.',
      ],
      headers: [
        { text: 'Image', align: 'start', sortable: false, value: 'img',},
        { text: 'Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Active', value: 'active', sortable: true },
        { text: 'Filters', value: 'filters', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  async mounted() {
    const now = DateTime.now().startOf('day');
    this.timeZoneOlson = now.zoneName;
    this.editedItem.timezone = this.timeZoneOlson;
    this.editedItem.from = now.toISO();
    this.editedItem.till = now.plus({months: 1}).toISO();

    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
        this.ads = (await eventManagerService.getAds()).data.data;
      }
    },

    openDialog(item) {
      this.editedIndex = this.ads.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showDialog = true
    },

    async openDesignDialog(item) {
      this.editedIndex = this.ads.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showDesignDialog = true;
      await this.updatePreviewImage();
    },

    async uploadImage() {
      if (this.uploadFile) {
        let formData = new FormData()
        formData.append("file", this.uploadFile, this.uploadFile.name);
        const response = await eventManagerService.uploadAdImage(this.editedItem.id, formData);
        if (response.data.status == "OK") {
          this.editedItem.img = response.data.msg;
          //await this.loadData();
          //this.closeDesignDialog();
        }
        this.$helpers.toastResponse(this, response.data, 'Image is uploaded successful!');
      }
    },

    async deleteAd (item) {
      if (confirm(`Are you sure you want to delete this ad ${item.name}?`)) {
        await eventManagerService.deleteAd(item.id);
        await this.loadData();
      }
    },

    closeDialog () {
      this.showDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },

    async saveDialog() {
      this.$refs.adForm.validate();
      this.$nextTick(async () => {
        if (this.valid) {
          await this.save();
          this.closeDialog();
        }
      });
    },

    async save() {
      console.log('Saving', this.editedItem);
      if (this.editedItem.filter_gender === '' || this.editedItem.filter_gender === 0) {
        this.editedItem.filter_gender = null;
      }
      if (this.editedItem.id) {
        await eventManagerService.putAd(this.editedItem.id, this.editedItem);
      }
      else {
        await eventManagerService.postAd(this.editedItem);
      }
      await this.loadData();
    },

    closeDesignDialog () {
      this.showDesignDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveDesignDialog() {
      await this.save();
      this.closeDesignDialog();
    },

    getFilterCount(ad) {
      return Object.keys(ad).filter(key => key.startsWith('filter_') && !!ad[key]).length;
    },

    checkAllActivities() {
      this.editedItem.filter_activity_types = [];
    },

    async ensureMarker(leaflet) {
      if (this.editedItem && this.editedItem.filter_coord_lat && this.editedItem.filter_coord_lon) {
        if (!this.marker) {
          this.marker = leaflet.addCircle([this.editedItem.filter_coord_lat, this.editedItem.filter_coord_lon], { color: "#00B46E", weight: 2 });
        }
      }
      if (!this.editedItem.filter_coord_radius) {
        this.editedItem.filter_coord_radius = 25 * 1000;
      }
      if (this.marker) {
        this.marker.setRadius(this.editedItem.filter_coord_radius);
        this.marker.setLatLng([this.editedItem.filter_coord_lat, this.editedItem.filter_coord_lon]);
      }

      console.log('>>>>', this.editedItem.filter_coord_lat, this.editedItem.filter_coord_lon, this.editedItem.filter_coord_radius);
    },

    async mapLoaded(leaflet) {
      var map = leaflet.map;
      //console.log('/////loaded///', map);

      await this.$nextTick();
      map.invalidateSize();

      this.ensureMarker(leaflet);
      //map.click
      var self = this;
      map.on("click", function(e){
        self.editedItem.filter_coord_lat = e.latlng.lat;
        self.editedItem.filter_coord_lon = e.latlng.lng;
        self.ensureMarker.call(self, leaflet);
      });
    },
    
    async updateRadius(v) {
      //console.log('filter_coord_radius', this.editedItem.filter_coord_radius, v);
      if (this.marker) {
        this.marker.setRadius(this.editedItem.filter_coord_radius);
      }
    },


  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Ads', disabled: true },
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Ad' : 'Edit Ad'
    },
    allActivitiesSelected() {
      return !this.editedItem.filter_activity_types || this.editedItem.filter_activity_types.length == 0;
    },
    unitTypeDisplay() {
      return this.profile == null || this.profile.unit == 'METRIC' ? 'km' : 'mi';
    },
    selectedLocation: {
      get() {
        if (!this.editedItem || !this.editedItem.filter_coord_lat) {
        console.log('LOC', 'zero');
          return [0, 0];
        }
        return [this.editedItem.filter_coord_lat, this.editedItem.filter_coord_lon];
      },
      set(v) {
        console.log('LOC', v);
      }
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    showMapDialog(visible) {
      if (visible) {
        this.ensureMarker(this.$refs.leaflet);
      }
    },

  }
};
</script>
<style lang="scss">
</style>

